define("tmp-for-all/routes/worksites/submissions", ["exports", "ember-cli-pagination/remote/route-mixin", "tmp-for-all/mixins/routes/view-my-worksites", "tmp-for-all/constants"], function (_exports, _routeMixin, _viewMyWorksites, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Route.extend(_routeMixin.default, _viewMyWorksites.default, {
    apm: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    queryParams: {
      order: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;
      var order = params.order ? params.order : 'declinedAt DESC, submittedAt DESC';
      delete params.order;
      return get(this, 'authManager.currentUser').then(function (user) {
        return _this.findPaged('worksite-search-result', R.merge(params, {
          filter: {
            order: order,
            where: {
              or: [{
                type: _constants.WORKSITE_MODEL_TYPES.ACCEPTED,
                applicantId: get(user, 'selectedDomainId'),
                isDeclined: true
              }, {
                type: _constants.WORKSITE_MODEL_TYPES.SUBMITTED,
                applicantId: get(user, 'selectedDomainId')
              }]
            }
          }
        }));
      }).catch(function (err) {
        get(_this, 'apm').captureError(err);
        _this.replaceWith('index');
      });
    },
    actions: {
      hideDeclinedWorksites: function hideDeclinedWorksites(checked) {
        var _this2 = this;
        var user = get(this, 'authManager.currentUser');
        var filter = {};
        if (checked) {
          var order = 'submittedAt DESC';
          filter = {
            filter: {
              order: order,
              where: {
                type: _constants.WORKSITE_MODEL_TYPES.SUBMITTED,
                applicantId: get(user, 'selectedDomainId'),
                isDeclined: false
              }
            }
          };
        } else {
          var _order = 'declinedAt DESC, submittedAt DESC';
          filter = {
            filter: {
              order: _order,
              where: {
                or: [{
                  type: _constants.WORKSITE_MODEL_TYPES.ACCEPTED,
                  applicantId: get(user, 'selectedDomainId'),
                  isDeclined: true
                }, {
                  type: _constants.WORKSITE_MODEL_TYPES.SUBMITTED,
                  applicantId: get(user, 'selectedDomainId')
                }]
              }
            }
          };
        }
        return this.findPaged('worksite-search-result', filter).then(function (results) {
          return set(_this2, 'controller.model', results);
        });
      }
    }
  });
  _exports.default = _default;
});