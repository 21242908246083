define("tmp-for-all/models/worksite-submission", ["exports", "ember-data", "tmp-for-all/models/worksite-base", "tmp-for-all/constants", "tmp-for-all/utils/conditions-util", "tmp-for-all/utils/html-comment-helpers", "tmp-for-all/utils/animations"], function (_exports, _emberData, _worksiteBase, _constants, _conditionsUtil, _htmlCommentHelpers, _animations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany,
    belongsTo = _emberData.default.belongsTo;
  var _Ember = Ember,
    RSVP = _Ember.RSVP,
    computed = _Ember.computed,
    get = _Ember.get,
    set = _Ember.set,
    $ = _Ember.$;
  var _default = _worksiteBase.default.extend({
    ajax: Ember.inject.service(),
    isAccepting: attr('boolean'),
    reviewComment: attr('string'),
    reviewDescription: attr('string'),
    declineComment: attr('string'),
    acceptComment: attr('string'),
    acceptDescription: attr('string'),
    status: computed('isDeclined', function () {
      return get(this, 'isDeclined') ? 'Declined' : 'Submitted';
    }),
    type: _constants.WORKSITE_MODEL_TYPES.SUBMITTED,
    acceptanceConditions: hasMany('acceptance-condition', {
      async: true
    }),
    customConditions: hasMany('custom-condition'),
    owner: belongsTo('tmp-user', {
      async: true
    }),
    addEmptyWorkItem: function addEmptyWorkItem() {
      this.store.createRecord('work-item', {
        worksiteSubmission: this
      });
    },
    createDraft: function createDraft() {
      var _this = this;
      set(this, 'isCreatingDraft', true);
      return get(this, 'ajax').worksiteAction(this, 'draft').then(function () {
        var draft = _this.store.peekRecord('worksite-draft', _this.id);
        if (draft) {
          get(_this, 'store').unloadRecord(draft);
        }
      }).catch(function () {
        return RSVP.reject(new Error('Worksite draft could not be created, please try again'));
      }).finally(function () {
        return set(_this, 'isCreatingDraft', false);
      });
    },
    decline: function decline(commentText) {
      var _this2 = this;
      set(this, 'isDeclining', true);
      if (!commentText || !(0, _htmlCommentHelpers.hasHtmlComment)(commentText)) {
        set(this, 'isDeclining', false);
        (0, _animations.jiggleALittleBit)($('.t-decline-textarea'));
        return RSVP.reject(new Error('Please enter a declined comment before clicking confirm'));
      }
      set(this, 'declineComment', commentText);
      return get(this, 'ajax').worksiteAction(this, 'decline').catch(function () {
        return RSVP.reject(new Error('Worksite could not be declined, please try again'));
      }).finally(function () {
        get(_this2, 'history').reload();
        set(_this2, 'isDeclining', false);
        set(_this2, 'hasDeclined', true);
      });
    },
    review: function review(description, commentText) {
      var _this3 = this;
      set(this, 'isReviewing', true);
      set(this, 'reviewDescription', description);
      set(this, 'reviewComment', commentText);
      return get(this, 'ajax').worksiteAction(this, 'review').catch(function () {
        return RSVP.reject(new Error('Worksite could not be reviewed, please try again'));
      }).finally(function () {
        get(_this3, 'history').reload();
        set(_this3, 'isReviewing', false);
      });
    },
    saveConditions: function saveConditions(selectedConditions, rcaId) {
      var _this4 = this;
      var worksiteId = get(this, 'id');
      var conditionIds = selectedConditions.map(function (condition) {
        return get(condition, 'id');
      });
      return get(this, 'ajax').updateWorksiteConditions(worksiteId, rcaId, conditionIds).then(function () {
        return (0, _conditionsUtil.refreshAcceptanceConditions)(_this4, 'worksiteSubmission', _this4.store);
      });
    },
    accept: function accept(description, commentText) {
      var _this5 = this;
      set(this, 'isAccepting', true);
      set(this, 'acceptDescription', description);
      set(this, 'acceptComment', commentText);
      return get(this, 'ajax').worksiteAction(this, 'accept').then(function () {
        var worksite = _this5.store.peekRecord('worksite', _this5.id);
        if (worksite) {
          get(_this5, 'store').unloadRecord(worksite);
        }
        // IE11
        // get(this, 'store').unloadRecord(this)
      }).catch(function (err) {
        var errorMsg = 'Worksite access permit could not be granted, please try again';
        var payloadErrors = get(err, 'payload.errors');
        if (payloadErrors && payloadErrors[0].code === 'ACCESS_ACTS') {
          errorMsg = payloadErrors[0].detail;
        }
        return RSVP.reject(new Error(errorMsg));
      }).finally(function () {
        return set(_this5, 'isAccepting', false);
      });
    },
    unclaim: function unclaim() {
      var _this6 = this;
      set(this, 'isClaiming', true);
      return this.get('ajax').worksiteAction(this, 'unclaim').then(function () {
        // See tmp-submission.js for note on this unorthodox approach\
        set(_this6, 'owner', null);
        set(_this6, 'currentState', _emberData.default.RootState.loaded.saved);
      }).catch(function () {
        return RSVP.reject(new Error('Worksite could not be unclaimed, please try again'));
      }).finally(function () {
        return set(_this6, 'isClaiming', false);
      });
    },
    claim: function claim(user) {
      var _this7 = this;
      set(this, 'isClaiming', true);
      return this.get('ajax').worksiteAction(this, 'claim', get(user, 'id')).then(function () {
        // See tmp-submission.js for note on this unorthodox approach\
        set(_this7, 'owner', user);
        set(_this7, 'currentState', _emberData.default.RootState.loaded.saved);
      }).catch(function () {
        return RSVP.reject(new Error('Worksite could not be claimed, please try again'));
      }).finally(function () {
        return set(_this7, 'isClaiming', false);
      });
    }
  });
  _exports.default = _default;
});