define("tmp-for-all/routes/planning-map/region", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP,
    get = Ember.get,
    tryInvoke = Ember.tryInvoke;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model(_ref) {
      var region = _ref.region;
      return RSVP.hash({
        regionName: region,
        region: _constants.REGIONS[region].id,
        map: {
          center: _constants.REGIONS[region].center,
          zoom: _constants.REGIONS[region].zoom
        },
        data: [],
        isLoadingData: false,
        rcaCollection: get(this, 'store').findAll('jurisdiction', {
          reload: true
        }).then(function (results) {
          return results.map(function (rca) {
            return {
              label: get(rca, 'displayName'),
              value: rca.id
            };
          }).sortBy('label');
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('region', model.regionName);
      tryInvoke(controller, 'afterSetup');
    }
  });
  _exports.default = _default;
});