define("tmp-for-all/app", ["exports", "tmp-for-all/resolver", "ember-load-initializers", "tmp-for-all/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin", "tmp-for-all/models/custom-inflector-rules"], function (_exports, _resolver, _emberLoadInitializers, _environment, _authenticatedRouteMixin, _customInflectorRules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  // Add any environment variables passed from the environment into the config.
  // See app/index.html for how these are injected. Removes it off the global
  // object once it's in config so that nowhere in the app relies on it.
  _environment.default.ENV_VARS = window.appConfig || {};
  _environment.default.supportEmail = _environment.default.ENV_VARS.SUPPORT_EMAIL;
  if (_environment.default.ENV_VARS.MAPBOX_TOKEN) {
    _environment.default.mapboxToken = _environment.default.ENV_VARS.MAPBOX_TOKEN;
  }
  _environment.default.linzToken = _environment.default.ENV_VARS.LINZ_API_TOKEN;
  if (_environment.default.ENV_VARS.OSRM_SERVICE_URL) {
    _environment.default.osrmServiceUrl = _environment.default.ENV_VARS.OSRM_SERVICE_URL;
  }
  _environment.default.googleTagManagerKey = _environment.default.ENV_VARS.GOOGLE_TAGMANAGER_KEY;
  if (_environment.default.ENV_VARS.SERVER_ADDRESS) {
    _environment.default.apiHost = _environment.default.ENV_VARS.SERVER_ADDRESS;
    _environment.default.apiPath = "".concat(_environment.default.apiHost, "/").concat(_environment.default.apiNamespace);
  } else {
    _environment.default.apiPath = "/".concat(_environment.default.apiNamespace);
  }
  if (_environment.default.ENV_VARS.OSM_SERVER_ADDRESS) {
    _environment.default.osmHost = _environment.default.ENV_VARS.OSM_SERVER_ADDRESS;
    _environment.default.osmPath = "".concat(_environment.default.osmHost, "/").concat(_environment.default.osmNamespace);
  } else {
    _environment.default.osmPath = "/".concat(_environment.default.osmNamespace);
  }
  if (_environment.default.ENV_VARS.MWS_AUDITS_PREFIX) {
    _environment.default.mwsAuditsPrefix = _environment.default.ENV_VARS.MWS_AUDITS_PREFIX;
  } else {
    _environment.default.mwsAuditsPrefix = '/v2/audits';
  }
  if (_environment.default.ENV_VARS.MWS_FEES_PREFIX) {
    _environment.default.mwsFeesPrefix = _environment.default.ENV_VARS.MWS_FEES_PREFIX;
  } else {
    _environment.default.mwsFeesPrefix = '/v2/fees';
  }
  if (_environment.default.ENV_VARS.MWS_APPLY_PREFIX) {
    _environment.default.mwsApplyPrefix = _environment.default.ENV_VARS.MWS_APPLY_PREFIX;
  } else {
    _environment.default.mwsApplyPrefix = '/apply';
  }
  if (_environment.default.ENV_VARS.MWS_SEARCH_PREFIX) {
    _environment.default.mwsSearchPrefix = _environment.default.ENV_VARS.MWS_SEARCH_PREFIX;
  } else {
    _environment.default.mwsSearchPrefix = '/v2/search';
  }
  if (_environment.default.ENV_VARS.MWS_DASHBOARD_PREFIX) {
    _environment.default.mwsDashboardPrefix = _environment.default.ENV_VARS.MWS_DASHBOARD_PREFIX;
  } else {
    _environment.default.mwsDashboardPrefix = '/v2/dashboard';
  }
  if (_environment.default.ENV_VARS.MWS_PROJECTS_PREFIX) {
    _environment.default.mwsProjectsPrefix = _environment.default.ENV_VARS.MWS_PROJECTS_PREFIX;
  } else {
    _environment.default.mwsProjectsPrefix = '/v2/projects';
  }
  if (_environment.default.ENV_VARS.APM_SERVICE_NAME) {
    _environment.default.apmServiceName = _environment.default.ENV_VARS.APM_SERVICE_NAME;
  } else {
    _environment.default.apmServiceName = 'mws-ember-ui';
  }
  if (_environment.default.ENV_VARS.APM_SERVER_URL) {
    _environment.default.apmServerUrl = _environment.default.ENV_VARS.APM_SERVER_URL;
  } else {
    _environment.default.apmServerUrl = 'http://localhost:8200';
  }
  if (_environment.default.ENV_VARS.APM_ENVIRONMENT) {
    _environment.default.apmEnvironment = _environment.default.ENV_VARS.APM_ENVIRONMENT;
  } else {
    _environment.default.apmEnvironment = 'local-dev';
  }
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  Ember.UnprotectedRoute = Ember.Route;
  Ember.Route = Ember.Route.extend(_authenticatedRouteMixin.default);
  var _default = App;
  _exports.default = _default;
});