define("tmp-for-all/components/search-parameters/selected-filters/selected-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    apm: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      var filter = get(this, 'filter');
      var filterType = filter.name;
      if (filterType === 'discrete-list') {
        get(this, 'updateFilterParams')(function (controller) {
          filter.getOptions(controller).then(function (options) {
            var selection = filter.getInitialSelection(null, options, get(_this, 'index'));
            if (!selection) {
              get(_this, 'apm').captureError(new Error('Couldn\'t find selection'), {
                optionsCount: options.length,
                index: _this.index,
                filter: filter
              });
              return [];
            }
            // Special case for STMS user filter:
            if (get(selection, 'data') && get(selection, 'data.preferredName') && get(selection, 'data.lastName')) {
              set(_this, 'listValue', get(selection, 'data.preferredName') + ' ' + get(selection, 'data.lastName'));
            } else {
              set(_this, 'listValue', get(selection, 'name'));
            }
          });
        });
      } else if (filterType === 'options-filter') {
        filter.getOptions().then(function (options) {
          var selection = filter.getInitialSelection(null, options, get(_this, 'index'));
          set(_this, 'listValue', selection.name);
        });
      }
    },
    filterValue: computed('filter', 'filterOption', 'listValue', function () {
      var filter = get(this, 'filter');
      var filterType = filter.name;
      var filterOption = get(this, 'filterOption');
      if (filterType === 'discrete-list' || filterType === 'options-filter') {
        return get(this, 'listValue');
      } else if (filterType === 'text-filter') {
        return filterOption;
      } else if (filterType === 'worksite-work-window' || filterType === 'single-date') {
        var dateRange = '';
        var dateFrom = filterOption.length ? filterOption[0] : null;
        var dateTo = filterOption.length > 1 ? filterOption[1] : null;
        if (dateFrom && dateTo) {
          dateRange = moment(dateFrom).format('D/M/YY') + ' - ' + moment(dateTo).format('D/M/YY');
        }
        return dateRange;
      }
    })
  });
  _exports.default = _default;
});