define("tmp-for-all/components/shared/rca-clash-resolution-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1W7nBSfg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"heading-block underline-coloured-green\"],[9],[0,\"\\n\\t\"],[7,\"h1\"],[9],[0,\"Comment on resolution\"],[10],[0,\"\\n    \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"users\",\"20px\",\"28px\",\"icon--in-heading icon--green\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"element-wrap clash-resolution-modal__textarea\"],[9],[0,\"\\n\\t\"],[1,[27,\"shared/form/text-area\",null,[[\"label\",\"value\",\"required\",\"class\",\"maxlength\",\"rows\"],[\"Notes\",[23,[\"rcaClashNote\"]],true,\"rca-clash-note-char-count\",255,5]]],false],[0,\"\\n\\t\"],[1,[27,\"character-counter\",null,[[\"characterCount\",\"characterLimit\",\"inputClass\"],[[23,[\"rcaClashNoteLength\"]],255,\"rca-clash-note-char-count\"]]],false],[0,\" \"],[7,\"div\"],[11,\"class\",\"margin-left-5\"],[9],[0,\" characters used\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"deprecated-grid align-right\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"tertiary-action t-secondary-button\"],[9],[0,\"\\n    Cancel\\n  \"],[3,\"action\",[[22,0,[]],\"closeModal\"]],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"primary-action\"],[9],[0,\"\\n    Publish\\n  \"],[3,\"action\",[[22,0,[]],\"saveRcaClashNote\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/rca-clash-resolution-modal/template.hbs"
    }
  });
  _exports.default = _default;
});