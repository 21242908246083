define("tmp-for-all/mixins/routes/layout-map-route-mixin", ["exports", "tmp-for-all/utils/route-helpers", "tmp-for-all/mixins/routes/get-tooltip-context-mixin", "tmp-for-all/constants", "ember-copy"], function (_exports, _routeHelpers, _getTooltipContextMixin, _constants, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed,
    RSVP = Ember.RSVP;
  var _default = Ember.Mixin.create(_getTooltipContextMixin.default, {
    authorization: Ember.inject.service(),
    ajax: Ember.inject.service(),
    apm: Ember.inject.service(),
    model: function model() {
      var layout = this.modelFor((0, _routeHelpers.getParentRouteName)(get(this, 'routeName')));
      var worksite = this.modelFor((0, _routeHelpers.getParentRouteName)((0, _routeHelpers.getParentRouteName)(get(this, 'routeName')))).get('latestWorksite');
      return RSVP.hash({
        layout: layout,
        worksite: worksite
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;
      var tmpType = get(this, 'tmpType');
      var tmp = this.modelFor(tmpType);
      // Needed to do the permission check in the afterModel instead of beforeModel because the model was needed for the permission check
      var permission = tmp.isGenericTmp ? 'editGlobalPermissionLayout' : get(this, 'siteSpecificRoutePermission');
      get(this, 'authorization').checkAllowableNavigation(this, permission, tmp).catch(function () {
        return _this.replaceWith('index');
      });
      var searchQuery = (0, _emberCopy.copy)(_constants.DEFAULT_PLANNINGMAP_SEARCH_QUERY, true);
      var startDate = model.layout.startDate || model.worksite.startDate;
      var endDate = model.layout.endDate || model.worksite.endDate;
      if (startDate && endDate) {
        searchQuery.dateRange = {
          from: startDate.toISOString(),
          to: endDate.toISOString()
        };
      } else {
        // default the start and end dates
        if (!startDate) {
          startDate = new Date();
          // If the new startDate is after the endDate then reset the startDate to 14 days before the endDate
          if (endDate && startDate > endDate) startDate = new Date(new Date(endDate).setDate(endDate.getDate() - 14));
        }
        if (!endDate) endDate = new Date(new Date(startDate).setDate(startDate.getDate() + 14));
        searchQuery.dateRange = {
          from: startDate.toISOString(),
          to: endDate.toISOString()
        };
      }
      set(this, 'searchQuery', searchQuery);
    },
    layout: computed.alias('controller.model.layout'),
    actions: {
      returnToEdit: function returnToEdit() {
        this.replaceWith(get(this, 'returnRoute'));
      },
      updateImpacts: function updateImpacts(impacts) {
        var addDetour = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var layout = this.layout;
        layout.set('impacts', impacts);
        if (addDetour) {
          this.replaceWith(get(this, 'returnDetourRoute'));
        } else {
          this.replaceWith(get(this, 'returnRoute'));
        }
      },
      displayErrorMessage: function displayErrorMessage(err) {
        get(this, 'apm').captureError(err);
        if (err.message.indexOf('Error on save') > -1) {
          this.flashMessages.danger('Error while saving impact');
        } else {
          this.flashMessages.danger(err.message);
        }
      },
      updateClashes: function updateClashes(boundingBox) {
        var searchQuery = get(this, 'searchQuery');
        searchQuery.boundingBox = boundingBox;
        set(this, 'searchQuery', searchQuery);
        return get(this, 'ajax').getPlanningMapFeatures(searchQuery).then(function (results) {
          return results.data;
        });
      }
    }
  });
  _exports.default = _default;
});