define("tmp-for-all/routes/worksites/list", ["exports", "ember-cli-pagination/remote/route-mixin", "tmp-for-all/mixins/routes/view-my-worksites", "tmp-for-all/constants"], function (_exports, _routeMixin, _viewMyWorksites, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Route.extend(_routeMixin.default, _viewMyWorksites.default, {
    apm: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    queryParams: {
      order: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;
      var order = params.order ? params.order : 'acceptedAt DESC';
      delete params.order;
      return get(this, 'authManager.currentUser').then(function (user) {
        return {
          order: order,
          where: {
            type: _constants.WORKSITE_MODEL_TYPES.ACCEPTED,
            applicantId: get(user, 'selectedDomainId'),
            isDeclined: false
          }
        };
      }).then(function (whereQuery) {
        return _this.findPaged('worksite-search-result', R.merge(params, {
          filter: whereQuery
        }));
      }).catch(function (err) {
        get(_this, 'apm').captureError(err);
        _this.replaceWith('index');
      });
    }
  });
  _exports.default = _default;
});