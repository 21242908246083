define("tmp-for-all/utils/html-comment-helpers", ["exports", "sanitize-html", "node-html-parser", "he"], function (_exports, _sanitizeHtml, _nodeHtmlParser, _he) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasHtmlComment = hasHtmlComment;
  _exports.sanitizeHtmlComment = sanitizeHtmlComment;
  var commentAllowedTags = ['p', 'h1', 'h2', 'b', 'u', 'i', 'ul', 'ol', 'li', 'hr', 'a'];
  function sanitizeHtmlComment(htmlString) {
    return (0, _sanitizeHtml.default)((0, _he.decode)(htmlString), {
      allowedTags: commentAllowedTags
    });
  }
  function hasHtmlComment(htmlString) {
    return (0, _nodeHtmlParser.parse)(htmlString).text !== '';
  }
  var _default = {
    sanitizeHtmlComment: sanitizeHtmlComment,
    hasHtmlComment: hasHtmlComment
  };
  _exports.default = _default;
});