define("tmp-for-all/components/pending-users-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Component.extend({
    displayError: function displayError(err) {
      if (err.payload && err.payload.errors && err.payload.errors.length) {
        get(this, 'flashMessages').danger(err.payload.errors[0].detail);
      } else {
        get(this, 'flashMessages').danger(err.message);
      }
    },
    actions: {
      approveUser: function approveUser(user, comment) {
        var _this = this;
        get(this, 'approveUser')(user, comment).then(function () {
          return get(_this, 'users').removeObject(user);
        }).catch(this.displayError.bind(this));
      },
      declineUser: function declineUser(user, comment) {
        var _this2 = this;
        get(this, 'declineUser')(user, comment).then(function () {
          return get(_this2, 'users').removeObject(user);
        }).catch(this.displayError.bind(this));
      }
    }
  });
  _exports.default = _default;
});