define("tmp-for-all/components/shared/threaded-history/item/file/component", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    classNames: ['history-uploaded-file'],
    authManager: Ember.inject.service('session'),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    modal: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    // explicitly included or can't mock during tests
    apiPath: _environment.default.apiPath,
    session: Ember.inject.service(),
    apm: Ember.inject.service(),
    token: computed.alias('session.data.authenticated.id'),
    isWorksite: Ember.computed.equal('history.constructor.modelName', 'worksite-history'),
    isImage: computed('file.mimeType', function () {
      var imageTypes = ['image/jpeg', 'image/png'];
      var mimeType = get(this, 'file.mimeType');
      return imageTypes.indexOf(mimeType) !== -1;
    }),
    backgroundImageStyles: computed('isImage', function () {
      var apiPath = get(this, 'apiPath');
      var fileID = get(this, 'file.id');
      var token = get(this, 'token');
      return Ember.String.htmlSafe("background-image: url('".concat(apiPath, "/files/").concat(fileID, "/download/?size=thumb&access_token=").concat(token, "');"));
    }),
    actions: {
      removeFile: function removeFile(file) {
        var _this = this;
        this.set('modal.options', {
          title: 'Delete File?',
          text: "Please confirm that you would like to delete this document? ".concat(get(file, 'filename')),
          confirmButton: 'Delete',
          confirmAction: function confirmAction() {
            get(_this, 'ajax').deleteFileById(get(file, 'id'), get(_this, 'history.id')).then(function () {
              return get(_this, 'refreshHistory')();
            }).then(function () {
              _this.get('modal').toggle();
              get(_this, 'flashMessages').success('The file has been deleted.');
            }).catch(function (err) {
              get(_this, 'apm').captureError(err);
              get(_this, 'flashMessages').danger('There was a problem deleting this File.');
            });
          }
        });
        this.get('modal').toggle();
      }
    }
  });
  _exports.default = _default;
});