define("tmp-for-all/authenticators/superuser", ["exports", "tmp-for-all/authenticators/loopback"], function (_exports, _loopback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This authenticator will only work when the current user is the superuser
   */
  var _default = _loopback.default.extend({
    apm: Ember.inject.service(),
    authenticate: function authenticate(userId, password) {
      var _this = this;
      if (userId === -1) {
        return this.get('ajax').post('/tmp-users/switch-user', {
          data: JSON.stringify({
            password: password
          })
        }).catch(function (err) {
          get(_this, 'apm').captureError(err);
          return Promise.reject(new Error('Could not be authenticated with these credentials.'));
        });
      } else {
        return this.get('ajax').post("/tmp-users/".concat(userId, "/create-access-token")).catch(function (err) {
          get(_this, 'apm').captureError(err);
          return Promise.reject(new Error('Could not be authenticated with these credentials.'));
        });
      }
    }
  });
  _exports.default = _default;
});