define("tmp-for-all/services/apm", ["exports", "tmp-for-all/config/environment", "@elastic/apm-rum"], function (_exports, _environment, _apmRum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Service = Ember.Service;
  var cfg = {
    serviceName: _environment.default.apmServiceName,
    serverUrl: _environment.default.apmServerUrl,
    serviceVersion: '',
    environment: _environment.default.apmEnvironment
  };
  var _default = Service.extend({
    initialised: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.apm = (0, _apmRum.init)(cfg);
      this.initialised = true;
    },
    captureError: function captureError(error) {
      if (!this.initialised) this.init();
      this.apm.captureError(error);
    },
    setUserContext: function setUserContext(ctx) {
      if (!this.initialised) this.init();
      this.apm.setUserContext(ctx);
    },
    setCustomContext: function setCustomContext(ctx) {
      if (!this.initialised) this.init();
      this.apm.setCustomContext(ctx);
    },
    startTransaction: function startTransaction(name, type) {
      if (!this.initialised) this.init();
      return this.apm.startTransaction(name, type);
    }
  });
  _exports.default = _default;
});