define("tmp-for-all/components/worksite-view/issue-access-permit-box/component", ["exports", "tmp-for-all/utils/html-comment-helpers"], function (_exports, _htmlCommentHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    notes: '',
    isAccepting: false,
    canSubmit: computed('notes', 'isAccepting', function () {
      return (0, _htmlCommentHelpers.hasHtmlComment)(get(this, 'notes')) && !get(this, 'isAccepting');
    }),
    submitButtonDisabled: computed.not('canSubmit'),
    historyDescription: computed('isAMA', function () {
      var isAMA = get(this, 'isAMA');
      if (isAMA) {
        return 'Access permit issued on behalf of the NZ Transport Agency';
      } else {
        return 'Access permit issued';
      }
    }),
    submissionLabel: 'Issue access permit'
  });
  _exports.default = _default;
});